<!--
* @description:
* @fileName tagManage.vue
* @author hvv
* @date 2022/01/14 10:57:06
!-->
<template>
  <div class="type-management-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          添加
        </el-button>
        <!-- <el-button :icon="Delete" type="danger" @click="handleDelete($event)">
          批量删除
        </el-button> -->
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item>
            <el-input v-model.trim="queryForm.name" clearable placeholder="请输入名称" />
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="id" prop="id" show-overflow-tooltip />
      <el-table-column label="标签名称" prop="tagName" show-overflow-tooltip />
      <el-table-column label="操作" show-overflow-tooltip width="185">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            <vab-icon icon="edit-2-line" />
            编辑
          </el-button>
          <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.pageNum" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    <edit :enterpriseCode='queryForm.enterpriseCode' ref="editRef" @fetch-data="fetchData" />
  </div>
</template>

<script>
import { routesWhiteList } from '@/config'
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'

import { Delete, Plus, Search } from '@element-plus/icons'
import {
  enterpriseQueryMaterialTag,
  enterpriseDeleteMaterialTag,
} from '@/api/content'
import { getUserEnterPriseAccount } from '@/api/user'
export default defineComponent({
  name: 'tagManage',
  components: {
    Edit: defineAsyncComponent(() => import('./components/tagEdit')),
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      queryForm: { name: '', pageNum: 1, pageSize: 10 },
      total: 0,
      listLoading: false,
      list: [],
      layout: 'prev, pager, next',
      editRef: null,
      enterPriseList: [],
    })

    const handleEdit = (row) => {
      if (row.id) {
        state.editRef.showEdit(row)
      } else {
        state.editRef.showEdit()
      }
    }

    const handleDelete = (row) => {
      if (row.id) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await enterpriseDeleteMaterialTag(row.id)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }

    const queryData = () => {
      fetchData()
    }

    const fetchData = async () => {
      state.listLoading = true
      const { data } = await enterpriseQueryMaterialTag(state.queryForm)
      state.list = data.list
      state.total = data.total
      state.listLoading = false
    }

    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      fetchData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      fetchData()
    }
    const fetchEnterPrise = async () => {
      let userInfoData = JSON.parse(localStorage.getItem('userInfo'))
      console.log(userInfoData)
      let params = {
        tid: userInfoData.tid,
        iamUid: userInfoData.uid,
      }
      const { data: userInfo } = await getUserEnterPriseAccount(params)
      state.enterPriseList = userInfo
      // state.queryForm.enterpriseCode = userInfo[0].enterpriseCode
    }

    onMounted(async () => {
      await fetchEnterPrise()
      await fetchData()
    })

    return {
      ...toRefs(state),
      handleEdit,
      queryData,
      fetchData,
      handleSizeChange,
      handleCurrentChange,
      handleDelete,
      Delete,
      Search,
      Plus,
    }
  },
})
</script>
<style lang="scss" scoped></style>
